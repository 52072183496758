import React, { Component } from "react"
import Layout from "../components/layout"
import Navigation2 from "../components/navigation2"
import HomeContacto from "../components/home-contacto"
import { Link, graphql } from "gatsby"
import ReactPlayer from "react-player"
import { motion } from "framer-motion"

export const query = graphql`
  query($slug: String!) {
    datoCmsPublicacion(slug: { eq: $slug }) {
      titulo
      contenido
      slug

      fotoOIlustracion {
        url
      }
      documentoPdf {
        url
        title
      }
      youtubeVideo {
        title
        url
      }
    }
  }
`

export const YoutubePlayer = ({ yt }) => {
  return (
    <ReactPlayer
      url={yt}
      playing
      className="react-player"
      width="100%"
      height="100%"
    />
  )
}

const Content = ({ data }) => {
  const content = data.datoCmsPublicacion

  if (content.youtubeVideo === null) {
    content.youtubeVideo = ""
  }
  if (content.documentoPdf === null) {
    content.documentoPdf = ""
  }

  return (
    <Layout pageTitle={content.titulo}>
      <div className="w-full lg:w-10/12 mx-auto">
        <Navigation2 />

        <div className="w-full lg:flex mx-auto">
          <div className="w-full lg:w-11/12 px-5 lg:px-0 mt-10 lg:mt-0">
            <Link
              to="/publicaciones"
              className="text-sm mb-5 block hover:underline"
            >
              Ver todas las publicaciones
            </Link>
            <h1 className="text-3xl lg:mt-10 lg:mb-10">{content.titulo}</h1>

            <motion.div
              initial={{ opacity: 0, y: -40 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4 }}
              className="post-preview mt-8 mb-20 lg:flex"
            >
              <div className="w-full lg:w-1/3">
                <img
                  src={content.fotoOIlustracion.url}
                  alt="{content.titulo}"
                  className="p-5 pl-0"
                />
              </div>
              <div className="w-full lg:w-2/3">
                <div
                  className="home-body"
                  dangerouslySetInnerHTML={{
                    __html: content.contenido,
                  }}
                ></div>
                {content.documentoPdf.url ? (
                  <div className="pdf-wrapper mt-5 ml-1">
                    <a href={content.documentoPdf.url} target="_blank">
                      Descargar el PDF
                    </a>
                  </div>
                ) : (
                  ""
                )}
                <div className="w-full mt-5 lg:mt-8">
                  {content.youtubeVideo.url ? (
                    <div className="player-wrapper">
                      <YoutubePlayer yt={content.youtubeVideo.url} />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>

      <HomeContacto />
    </Layout>
  )
}

export default Content
